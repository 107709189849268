import React from "react";
import { styled, Box, Typography } from "@mui/material"
import { fonts } from "../../assets/styles/theme.ts";
import RenderImg from "../RenderImg/RenderImg.tsx";
import Remove from "./Remove.svg";



interface Props {
  label: string;
  colorBorder: string;
  svg?: string;
}
const customRow = ({ colorBorder, label, svg }: Props) => {
  const translations = {
    'rojo': 'red',
    'azul': 'blue',
    'verde': 'green',
    'amarillo': 'yellow',
    'naranja': 'orange',
    'morado': 'purple',
    'rosa': 'pink',
    'blanco': 'white',
    'negro': 'black',
    'gris': 'gray',
    'marrón': 'brown',
    'beige': 'beige',
    'celeste': 'sky blue',
    'turquesa': 'turquoise',
    'violeta': 'violet',
    'lila': 'lilac',
    'salmon': 'salmon',
    'púrpura': 'purple',
    'plateado': 'silver',
    'dorado': 'gold',
    'crema': 'cream',
    'colorido': 'colorful',
    'lavanda': 'lavender',
    'verde claro': 'light green',
    'azul claro': 'light blue',
    'rojo claro': 'light red',
    'amarillo claro': 'light yellow',
    'verde oscuro': 'dark green',
    'azul oscuro': 'dark blue',
    'rojo oscuro': 'dark red',
    'gris oscuro': 'dark gray',
    'negro carbón': 'charcoal black',
    'blanco roto': 'off white',
    'gris claro': 'light gray',
  };
  const colorBorderE = translations[colorBorder] || colorBorder;
  return <Styled colorBorder={colorBorderE}>
    <RenderImg img={svg ? `data:image/png;base64,${svg}` : ""} width={"35px"} />
    <StyledTypoGraphy>{label}</StyledTypoGraphy>
  </Styled>
}
export default customRow;

interface StyledProps {
  colorBorder?: string;
}


const Styled = styled(Box) <StyledProps>`
  border: ${({ colorBorder }) => (colorBorder && `3px solid ${colorBorder}`)};
  display: flex;
  align-items:center;
  gap: 5px;
  padding: 5px;
  border-radius: 16px;
`;
const StyledTypoGraphy = styled(Typography)`
  font-family:${fonts.primary};
  font-size: 13px;
  font-weight: 700;
  margin: 0px;
  line-height: 16.8px;
`;