export const formatearFecha = (fecha: string): string => {
  if (!fecha) return ""; 

  const fechaObj = new Date(fecha);
  if (isNaN(fechaObj.getTime())) return ""; 

  return fechaObj.toLocaleDateString("es-ES", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};