import axios from "./axios";

//Laboratorio
export const getIntervenciones = () => axios.get("/lastThreeIntervencionPuntoToma");
export const getMuestraConteo = () => axios.get("/muestraConteoPorMes");
export const getLatestOrdenesDeTrabajo = ()=>axios.get("/last10OrdenesTrabajo");

//Cliente
export const getLatestMuestras = () => axios.post("/last10InformeMuestra")

//
export const getImgLinkAyuda = () => axios.get("/lookupTenant/1")

