import React from "react";
import styled from "styled-components";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import { colors, fonts } from "../../assets/styles/theme.ts";
import dayjs from "dayjs";
import Tables from "../Tables/Tables.jsx";
const BarChartComponent = (props) => {
  const months = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const renderSeries = () => {
    const defaultData = [
      { data: [50, 80, 70], color: "#50A449", label: "Normal" },
      { data: [50, 80, 70], color: "#dbdb4f", label: "Precaución" },
      { data: [50, 80, 70], color: "#E70404", label: "Peligro" },
    ];
    const render =
      props.series?.map((res) => {
        return { data: res.data, color: res.color, label: res.label };
      }) ?? defaultData;

    return render;
  };

  const StyledHome = styled.div`
    display: flex;
    justify-content: center;
    .MuiChartsAxis-directionX {
      .MuiChartsAxis-tick {
        font-weight: bold;
      }
      .MuiChartsAxis-tickLabel {
        transform: rotate(-40deg) translate(-10px, 5%);
        text-anchor: end;

        tspan {
          font-size: 20px;
        }
      }
    }

    .MuiChartsAxis-directionY {
      .MuiChartsAxis-line {
        display: none;
      }
      .MuiChartsAxis-tick {
        display: none;
      }
      .MuiChartsAxis-tickLabel {
        tspan {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
  `;
  const StyledReportesAnalisis = styled.div`
    display: flex;
    justify-content: center;
    .MuiChartsAxis-directionY {
      .MuiChartsAxis-line {
        display: none;
      }
      .MuiChartsAxis-tick {
        display: none;
      }
      .MuiChartsAxis-tickLabel {
        tspan {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }
    .MuiBarLabel-root{
      font-weight: bold;
      font-size: 40px;
      font-family: ${fonts.primary};
      fill: white;
    } ; 
    .MuiChartsAxis-tickLabel {
        tspan {
          font-size: 20px;
        }
    }
    .styledTables{
      height: auto;
    th{
      background-color:#b6b4b457;
    }
    .MuiTableCell-root {
      img{
        display: none;
      }
    }
    .MuiTableContainer-root{
      width: 60%;
      margin:auto;
    }
  }
  `;
  const StyledReportesCausasDeFalla = styled.div`
  display: flex;
  justify-content: center;
    svg{
      padding: 20px;
    }
    .MuiPieArcLabel-root{
      font-weight: bold;
      font-size: 40px;
      font-family: ${fonts.primary};
    } ; 
  `;
  const StyledReportesDemoras = styled.div`
   
    display: flex;
    justify-content: center;
    
    .MuiChartsAxis-directionX {
      .MuiChartsAxis-tick {
        font-weight: bold;
      }
      .MuiChartsAxis-tickLabel {
        text-anchor: end;
        tspan {
          font-size: 20px;
        }
      }
    }

    .MuiChartsAxis-directionY {
      .MuiChartsAxis-line {
        display: none;
      }
      .MuiChartsAxis-tickContainer{
        display: none;
      }
      .MuiChartsAxis-tick {
        display: none;
      }
      .MuiChartsAxis-tickLabel {
        tspan {
          font-size: 20px;
          font-weight: bold;
          
        }
      }
    }
    .MuiBarLabel-root{
      font-weight: bold;
      font-size: 40px;
      font-family: ${fonts.primary};
      fill: white;
    } ; 
  `;
  const StyledReportesTiempo = styled.div`
    .styledTables{
    th{
      background-color:#b6b4b457;
    }
    .MuiTableCell-root {
      img{
        display: none;
      }
    }
  }
  `;

  const StyledReportesCambios = styled.div`
  .styledTables{
    th{
      background-color:#b6b4b457;
    }
    .MuiTableCell-root {
      img{
        display: none;
      }
    }
  }
    
  `;

  let Styled;
  switch (props.styled) {
    case "reportesAnalisis":
      Styled = StyledReportesAnalisis;
      break;
    case "reportesCausasDeFalla":
      Styled = StyledReportesCausasDeFalla;
      break;
    case "reportesCausasDemoras":
      Styled = StyledReportesDemoras;
      break;
    case "reportesCausasTiempo":
      Styled = StyledReportesTiempo;
      break;
    case "reportesCausasCambios":
      Styled = StyledReportesCambios;
      break;
    default:
      Styled = StyledHome;
      break;
  }

  const renderBarchart = () => {
    const sizeFallas = {
      width: 900,
      height: 600,
    };

    switch (props.type) {
      case "analisis":
        return (
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <BarChart
              xAxis={[{
                scaleType: "band",
                data: props.footerLabel ?? [],
              }]}
              sx={{
                "& .MuiBarElement-root:nth-child(1)": {
                  fill: "#1316b9",
                },
                "& .MuiBarElement-root:nth-child(2)": {
                  fill: "#f38004",
                },
              }}
              grid={{ horizontal: true }}
              series={[{
                data: props.series?.map((item) => item.value),
              }]}
              width={props.width ?? 1000}
              height={props.height ?? 500}
              barLabel="value"
            />
            {
              props.rows && props.rows.map((row, index) => (
                <Tables
                  key={index}  // Siempre es importante usar una key única para cada componente en listas
                  typeTable={"prop"}
                  columns={props.columns && props.columns}
                  rows={row}  // Cada "row" será un subarray de datos
                  cRows={20}
                  caption={props.caption}
                  labelCaption={props.labelCaption && props.labelCaption[index]}  // Asigna la etiqueta según el índice
                  pagination={false}
                />
              ))
            }
          </div>
        );
      case "fallas":
        return (

          <PieChart
            series={props.series.map((serie) => ({
              ...serie,
              arcLabel: (item) => `${item.value}% `,
            }))}
            slotProps={{
              tooltip: {
                formatter: (item) => `${item.label}: ${item.value}% `,
              },

            }}
            width={400}
            height={200}
            {...sizeFallas}
          />

        );

      case "demoras":
        return (
          <BarChart
            xAxis={[{
              scaleType: "band",
              data: props.footerLabel ?? [],
            }]}
            series={[{
              data: props.series?.map((item) => item.value),
            }]}
            sx={{
              "& .MuiBarElement-root": {
                fill: "#1316b9",
              },

            }}
            grid={{ horizontal: true }}
            width={props.width ?? 1000}
            height={props.height ?? 500}
            barLabel="value"
          />
        )
      case "tiempo":
        return (
          <>
            <LineChart
              yAxis={[
                {
                  min: 0,
                  max: props.seriesTiempo.flat().reduce((max, num) => (num > max ? num : max), -Infinity),
                  line: { display: true },
                  colorMap: {
                    type: 'piecewise',
                    thresholds: [0, 10],
                    colors: ['#3364ed', '#3364ed', '#3364ed'],
                  },
                },
              ]}
              xAxis={[
                {
                  data: props.series && props.series,
                  scaleType: "band",
                },
              ]}
              grid={{ horizontal: true }}
              series={[
                { data: props.seriesTiempo[0] },
              ]}
              height={400}
            />
            <Tables
              typeTable={"prop"}
              columns={props.columns && props.columns}
              rows={props.rows && props.rows}
              cRows={20}
              // totalRows={itemsCount}
              // actualpage={paginaActual}
              pagination={false}
            // page={(e) => setPaginaActual(e + 1)}
            // pages={pageCount}
            />
          </>
        )
      case "cambios":
        return (
          <Tables
            typeTable={"prop"}
            columns={props.columns && props.columns}
            rows={props.rows && props.rows}
            cRows={20}
            // totalRows={itemsCount}
            // actualpage={paginaActual}
            pagination={false}
          // page={(e) => setPaginaActual(e + 1)}
          // pages={pageCount}
          />
        )
      default:
        return (
          <BarChart
            slotProps={{
              legend: {
                direction: "row",
                position: { vertical: "top", horizontal: "middle" },
                labelStyle: {
                  fontSize: 25,
                  padding: 5,
                },
                padding: 0,
              },
            }}
            xAxis={[{ scaleType: "band", data: props.footerLabel ?? months }]}
            yAxis={[
              !props.yDecimal
                ? {
                  min: 0,
                  max: 90,
                  line: { display: false },
                }
                : {
                  min: 11.0,
                  max: 15.5,
                  line: { display: false },
                },
            ]}
            series={renderSeries()}
            width={props.width ?? 1000}
            height={props.height ?? 500}
            grid={props.grid ?? { horizontal: true }}
          />
        );
    }
  };

  return (

    <Styled>
      {renderBarchart()}
    </Styled>
  );
};

export default BarChartComponent;
