import React, { useEffect } from "react";
import { Styled, DivImg, Title, DivButtonsNav } from "./HeaderStyled";
import { useAuth } from "../../context/AuthContext.jsx";
import { useNavigate } from "react-router-dom";
import { datos } from "./datos.js";
import Usuario from "./Usuario/Usuario.jsx";
import LimsOil from "../../assets/Login/LimsOil.png";
import RenderImg from "../RenderImg/RenderImg.tsx";
import CustomButton from "../Button/CustomButton.tsx";
import Modal from "../Modal/Modal.jsx";
import { getImgLinkAyuda } from "../../api/Inicio.js"
const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated, token } = useAuth();
  const [data, setData] = React.useState("");
  const [statusModal, setModalStatus] = React.useState(false);
  const [statusModalDefault, setModalStatusDefault] = React.useState(false);
  const [typeModal, setTypeModal] = React.useState("");
  const [labelModal, setLabelModal] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [imgLogo, setImgLogo] = React.useState("");
  const [linkAyuda, setLinkAyuda] = React.useState("");
  const roleUser = token?.rol;

  useEffect(() => {
    const fetchImgAyuda = async () => {
      try {
        const response = await getImgLinkAyuda();
        if (response) {
          setImgLogo(response.data.logo);
          setLinkAyuda(response.data.manualUsuario);
        }
      } catch (error) {
        console.error("Error fetching image:", error);
      }
      if (!isAuthenticated) {
        setImgLogo(null);
      }
    };
    fetchImgAyuda();
  }, [isAuthenticated]);


  console.log(linkAyuda);


  const handleClickButtonNav = (value, dt, role, event) => {
    setData(dt);
    setOpen(true);
    setTimeout(() => {
      switch (value) {
        case "Inicio":
          setModalStatus(false);
          navigate("/task");
          break;
        case "Informes de Muestras":
          setModalStatus(false);
          navigate("/informesmuestras");
          break;
        case "Proveedores":
          setModalStatus(false);
          navigate("/proveedores");
          break;
        case "Intervenciones":
          setModalStatus(false);
          navigate("/intervenciones");
          break;
        case "Reportes":
          setModalStatus(false);
          navigate("/reportes");
          break;
        case "Ayuda":
          setModalStatus(false);
          break;
        case "Configuración":
          switch (role) {
            case "SUPERADMIN":
              setModalStatus(true);
              setModalStatusDefault(false);
              break;
            case "TENANT":
              setModalStatus(true);
              break;
            default:
              console.log("Role no reconocido");
              break;
          }
          break;
        case "Usuarios":
          switch (role) {
            case "SUPERADMIN":
              setModalStatus(false);
              setModalStatusDefault(false);
              navigate("/usuarios");
              break;
            default:
              console.log("Role no reconocido");
              break;
          }
          break;
        default:
          setModalStatus(true);
          break;
      }
    }, 20);
    setLabelModal(
      value
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
    );
  };


  const Buttons = () => {
    const buttons = datos?.map((ress) => {
      return ress[roleUser]?.buttons?.map((res, key) => (
        <div key={key} style={{ display: "flex", alignItems: "center" }}>
          {res.iconMaterial ? res.iconMaterial : <RenderImg img={res.icon} />}
          
          <CustomButton
            onClick={(event) => {
              if (linkAyuda && res.label === "Ayuda") {
                window.open("https://" + linkAyuda, "_blank");
              } else {
                handleClickButtonNav(
                  res.label,
                  ress[roleUser][res.label.toLowerCase()],
                  roleUser,
                  event,
                );
              }
            }}
            
            label={res.label}
            className={"buttonsNav"}
          />
        </div>
      ));
    });
    return <DivButtonsNav>{buttons}</DivButtonsNav>;
  };

  return (
    <Styled>
      <DivImg>

        {isAuthenticated && imgLogo ?
          <img
            key={imgLogo}
            src={`data:image/png;base64,${imgLogo}`}
            alt="Profile" className="img"
            style={{ width: '100%', objectFit: 'cover', height: '100%' }} />
          : <RenderImg img={LimsOil} className="img" />}


        {!isAuthenticated && (
          <Title>
            Software de Gestión de Laboratorios de Análisis de Lubricantes
          </Title>
        )}
      </DivImg>
      {isAuthenticated && (
        <>
          {Buttons()}
          <Usuario />
        </>
      )}
      <Modal
        datos={data}
        labelModal={labelModal}
        isOpen={statusModal}
        onClose={() => setModalStatus(false)}
        onModal={open}
        modalNav
        role={roleUser}
        type={"nav"}
      />
      <Modal
        datos={data}
        isOpen={statusModalDefault}
        onClose={() => setModalStatusDefault(false)}
        onModal={open}
        type={typeModal}
      />
    </Styled>
  );
};

export default Header;
