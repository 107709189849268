import React, { useEffect, useState } from 'react';
import {
  DashBoardFooter,
  BoxLabelFooter,
  TitleFooter,
  DescriptionFooter,
  BoxTableFooter,
} from '../DashboardLaboratorioStyled';
import { getIntervenciones } from "../../../../../api/Inicio";
import { formatearFecha } from '../../../../../components/Utils/Utils.ts';
import Tables from "../../../../../components/Tables/Tables";
const DashboardFooterLaboratorio = () => {
  const [rows, setRows] = useState("");
  const columns = [
    { id: "equipo", label: "Equipo" },
    { id: "fechaAnalisis", label: "Fecha Análisis" },
    { id: "ptodetoma", label: "Pto.de Toma" },
    { id: "tipo", label: "Tipo" },
  ]
  const handleClickButonTable = (value, valueRow) => {
    console.log(value, valueRow);
  }
  useEffect(() => {
    const fetchIntervenciones = async () => {
      try {
        const res = await getIntervenciones();
        setRows(
          res.data.map((res) => {
            const fechaFormat = res.fecha_inte;
            const fecha = formatearFecha(fechaFormat)
           return {
              equipo: res.equipo.desc_equi,
              fechaAnalisis: fecha,
              ptodetoma: res.punto_toma.desc_pto,
              tipo: res.tipo.description,
            };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };

    fetchIntervenciones();
  }, []);
  return (
    <DashBoardFooter>
      <BoxLabelFooter>
        <TitleFooter>Intervenciones</TitleFooter>
        <DescriptionFooter>Aquí podrá consultar las últimas 3 intervenciones registradas.
          Para crear nuevas intervenciones o realizar consultas, presione  la opción “Intervenciones”  que se encuentra en la cabecera
        </DescriptionFooter>
      </BoxLabelFooter>
      <BoxTableFooter>
        <Tables
          typeTable={"prop"}
          columns={columns}
          rows={rows}
          cRows={6}
          pagination={false}
          onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
          onClickSwitch={false}
        />
      </BoxTableFooter>
    </DashBoardFooter>
  )
}

export default DashboardFooterLaboratorio