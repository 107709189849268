import React, { useReducer, useState, useEffect } from 'react';
import Input from '../../../../Input/Input.jsx';
import DropZone from '../../../../DropZone/DropZone.jsx';
import CustomButton from '../../../../Button/CustomButton.tsx';
import MenuItem from '@mui/material/MenuItem';
import Checkboxx from '../../../../Checkbox/Checkboxx.jsx';
import { useNavigate } from 'react-router-dom';
import { StyledModalcomponentMuestras } from '../../../Styled.jsx';
import { getClienteOptions, imagenesUploadZip } from '../../../../../api/Clientes.js';
import { getAllOrdenByCliente } from '../../../../../api/Muestras.js';
import DropZoneFiles from '../../../../DropZone/DropZoneFiles.jsx';
import DropZoneZipImages from '../../../../DropZone/DropZoneZipImages.jsx';
import Toast from '../../../../Toast/Toast.jsx';
import Preloader from '../../../../Preloader/Preloader.jsx';
import { useAuth } from '../../../../../context/AuthContext.jsx';

const ImportacionImagenesModal = (props) => {
  const { token } = useAuth();
  // const propsImportacionResultados = (datosUnidades) => {

  //   return {}
  // }
  // const {} = propsImportacionResultados(props.datos);
  const navigate = useNavigate();
  const [formCompleto, setFormCompleto] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);

  const [ordenes, setOrdenes] = useState([]);
  const [selectedOrden, setSelectedOrden] = useState(null);
  const [isLoadingOrdenes, setIsLoadingOrdenes] = useState(false);

  const [zipFile, setZipFile] = useState([]);

  useEffect(() => {
    const fetchClientes = async () => {
      const res = await getClienteOptions();
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_cli,
      }));
      setClientes(unidades);

      if (token.clienteId !== "") {

        const matchOption = unidades.find(
          (option) => option.value === parseInt(token.clienteId, 10)
        );

        if (matchOption) {
          setSelectedCliente(matchOption)
          fetchOrdenes(matchOption);
        }

      }
    };

    fetchClientes();
  }, []);

  const fetchOrdenes = async (e) => {
    setOrdenes([]);
    setSelectedOrden(null);
    if (e === null) {
    } else {
      setIsLoadingOrdenes(true);
      const res = await getAllOrdenByCliente(e.value);
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_otra,
      }));
      setOrdenes(unidades);
      setIsLoadingOrdenes(false);
    }
  };

  const handleClickConfirmar = async () => {
    setIsLoading(true);
    try {
      const response = await imagenesUploadZip({ file: zipFile[0].file }, selectedOrden.value);
      console.log(response)
      setMsjToast(response.data || "Las imágenes fueron cargadas satisfactoriamente");
      setSuccess(true);
      setIsLoading(false);

      if (token.clienteId !== "") {
        setSelectedOrden(null);
        setZipFile([]);
      } else {
        setSelectedCliente(null);
        setOrdenes([]);
        setSelectedOrden(null);
        setZipFile([]);
      }

    } catch (err) {
      const errorMessage = err.response?.data.message || "Ocurrió un error al cargar las imágenes";
      setMsjToast(errorMessage);
      setError(true);
      setIsLoading(false);
    }
  };

  const handleOnUploadArchivo = (file) => {
    setZipFile(file[0])
  }

  const handleDeleteArchivo = (file, index) => {
    setZipFile([])
  }

  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    cliente: '',
    ordenTrabajo: '',
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '';
    const camposaValidar = ['cliente', 'ordenTrabajo'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto);
  }, [form]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const fieldValue = value;
    setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  }
  const titulo = () => {
    const titulo = "Importación de  Imagenes"
    return titulo
  }
  const handleClickCancelar = () => {
    if (props.onClick) {
      props.onClick();
    }
    navigate(-1);
  };

  const renderButtons = () => {
    return (
      <>
        <CustomButton
          onClick={handleClickCancelar}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          onClick={handleClickConfirmar}
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          disabled={selectedOrden === null || zipFile.length === 0}
        />

      </>
    );
  };
  const onFileChange = (file) => {
    console.log(file);
    setSelectedFile(file);
  };

  return (
    <StyledModalcomponentMuestras sx={{
      width: "60%",
      padding: "40px",
      '@media (max-width: 768px)': {
        width: "90%",
      },
    }}>
      <Preloader title="Cargando..."
        subtitle={"Espere unos Segundos por favor..."}
        loading={isLoading} />
      <div className="toast" style={{ zIndex: "1000", left: "0px" }}>
        {success ? (
          <Toast title={msjToast} timeClose={3000} close={() => setSuccess(false)} />
        ) : null}
        {error ? (
          <Toast
            type={"error"}
            title={msjToast}
            timeClose={3000}
            close={() => setError(false)}
          />
        ) : null}
      </div>
      <div className="importaciones">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="divInputs">
            <div className="divUnoInput">
              <Input
                label="Cliente"
                classnamediv="divInputImportaciones divInput"
                className="input inputImportaciones"
                variant="outlined"
                size="medium"
                name="cliente"
                reactSelect
                options={clientes}
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e);
                  fetchOrdenes(e);
                }}
                placeholder={token.clienteId !== "" ? "Cliente" : selectedCliente === null ? "Seleccione un Cliente" : "Cliente"}
                isDisabled={token.clienteId !== "" || clientes.length < 1}
                isClearable
              />
              <Input
                label="Orden de Trabajo"
                classnamediv="divInputImportaciones divInput"
                className="input inputImportaciones"
                variant="outlined"
                size="medium"
                name="ordenTrabajo"
                reactSelect
                options={ordenes}
                value={selectedOrden}
                onChange={(e) => {
                  setSelectedOrden(e);
                }}
                placeholder={isLoadingOrdenes ? "Cargando..." : selectedCliente !== null && ordenes.length < 1 ? "No se encontraron ordenes" : selectedCliente !== null && selectedOrden === null ? "Seleccione una Orden" : "Orden de Trabajo"}
                isDisabled={ordenes.length < 1}
                isClearable
                isLoading={isLoadingOrdenes}
              />
            </div>
            <div className="divDosInput">
              {/*<Checkboxx label="¿Reemplazar resultados?" name="remplazar" onChange={(e) => { console.log(e) }} value="pepe" />*/}
              <DropZoneZipImages archivos={zipFile} onUploadFiles={handleOnUploadArchivo} onClickDelete={(index, file) => { handleDeleteArchivo(index, file) }} />
            </div>
            <div className="divButtonsItem">
              {renderButtons()}
            </div>
          </div>
        </form>
      </div>
    </StyledModalcomponentMuestras>
  )
}

export default ImportacionImagenesModal