import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import Tables from '../../components/Tables/Tables';
import Modal from '../../components/Modal/Modal';
import Modal2 from '../../components/Modal/Modal';
import { buttonsReportes } from '../../components/ButtonsTable/ButtonsTable';
import { useAuth } from '../../context/AuthContext';
import { deleteReporte, demoraPromedioConjuntoAnalisis, getAllReportes, puntoTomasPeriodo, reporteCausasFalla, reporteConteoPorMesDiagnostico, reporteDiasDemoraOrdenesTrabajo, reportePorEstadoMensual, tiempoMedioEntreFallas } from '../../api/Clientes';
import Preloader from '../../components/Preloader/Preloader';


const columns = [
  { id: "fecha", label: "Fecha Reporte" },
  { id: "tipo", label: "Tipo de Reporte" },
  { id: "autor", label: "Autor" },
  { id: "cliente", label: "Cliente" },
  { id: "buttons" },
]


const Reportes = () => {

  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [statusModalView, setStatusModalView] = useState(false);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [key, setKey] = useState(0);

  const [rows, setRows] = useState([]);
  const [orderBy, setOrderBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [paginaActual, setPaginaActual] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [itemsCount, setItemsCount] = useState(0);
  const [reporteId, setReporteId] = useState("");

  const [formToView, setFormToView] = useState(null);
  const [tipoReporteToView, setTipoReporteToView] = useState(null);
  const [valoresToView, setValoresToView] = useState(null);
  const [tipoRequestToView, setTipoRequestToView] = useState(null);
  const [headerReporteToView, setHeaderReporteToView] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const fetchReportes = () => {
    getAllReportes(paginaActual, orderBy, searchTerm).then((res) => {
      const modifiedItems = res.data.items.map(item => {
        let data = {};

        data.tipo = item.desc_rep ?? "";
        data.autor = item.created_by ?? "";
        data.cliente = item.cliente_id.description ?? "";

        // Crear un objeto Date con la fecha en formato ISO
        if (item.createdate !== null) {
          const date = new Date(item.created_date);

          // Extraer el día, mes y año
          const day = String(date.getDate()).padStart(2, '0'); // Asegura dos dígitos
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes entre 1 y 12
          const year = date.getFullYear();

          // Modificar el created_date al formato DD/MM/AAAA
          data.fecha = `${day}/${month}/${year}`;
        }
        data.data = item;
        return data;
      });
      
      // Establecer las filas con las fechas modificadas
      setRows(modifiedItems);
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    });
  }

  const fetchReportesBySuccess = () => {
    getAllReportes(paginaActual, orderBy, searchTerm).then((res) => {
      const modifiedItems = res.data.items.map(item => {
        let data = {};

        data.tipo = item.desc_rep ?? "";
        data.autor = item.created_by ?? "";
        data.cliente = item.cliente_id.description ?? "";

        // Crear un objeto Date con la fecha en formato ISO
        if (item.createdate !== null) {
          const date = new Date(item.created_date);

          // Extraer el día, mes y año
          const day = String(date.getDate()).padStart(2, '0'); // Asegura dos dígitos
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes entre 1 y 12
          const year = date.getFullYear();

          // Modificar el created_date al formato DD/MM/AAAA
          data.fecha = `${day}/${month}/${year}`;
        }
        data.data = item;
        return data;
      });

      setPaginaActual(res.data.totalPages)

      // Establecer las filas con las fechas modificadas
      setRows(modifiedItems);
      setPageCount(res.data.totalPages);
      setItemsCount(res.data.totalItems);
    });
  }

  useEffect(() => {
    fetchReportes();
  }, [paginaActual, orderBy, searchTerm])

  const handleOrder = (value) => {
    let auxValue;
    switch (value) {
      case "Fecha Reporte":
        value = "Fecha Inicio";
        break;
      case "Tipo de Reporte":
        value = "Tipo";
        break;
      case "Autor":
        value = "Creado Por";
        break;
      default:
        break;
    }

    if (value === "Apellido") auxValue = "lastName";
    if (value === "Nombre") auxValue = "firstName";
    if (value !== "Apellido" && value !== "Nombre") auxValue = value;

    let valor;
    if (orderBy === "" || orderBy.replace("-", "").length !== auxValue.length) {
      valor = auxValue;
    } else {
      valor = orderBy;
    }

    if (valor.indexOf("-") > -1) {
      const updatedValue = valor.replace("-", "");
      setOrderBy(updatedValue);
    } else {
      const updatedValue = "-" + valor;
      setOrderBy(updatedValue);
    }
  };

  const handleVerReporte = async (reporte) => {

    let form = {
      clienteId: reporte.data.cliente_id.id || "",
      plantaId: reporte.data.planta_id.id || "",
      equipoId: reporte.data.equipo_id.id|| "",
      fechaInicio: reporte.data.fecha_inicio_rep,
      fechaFin: reporte.data.fecha_fin_rep
    };
    
    let tipoReporte = {};
    let headerReporte = {
      "cliente": {label: reporte.data.cliente_id.description || ""},
      "planta": {label: reporte.data.planta_id.description || ""},
      "equipo": {label: reporte.data.equipo_id.description || ""}
    }
    let valores = [];

    switch (reporte.data.desc_rep) {
      case 'Reporte de Tiempo Medio Entre Fallas':
        await tiempoMedioEntreFallas(form).then((res) => {
          valores = res.data;
        })
        form.tipoReporte = "tiempo"
        tipoReporte.value = "tiempo"
        tipoReporte.label = "Días de demora por órden de trabajo"
        break;
      case 'Reporte de Demora Promedio por Conjunto de Análisis':
        await demoraPromedioConjuntoAnalisis(form).then((res) => {
          valores = res.data;
        });
        form.tipoReporte = "analisis"
        tipoReporte.value = "analisis"
        tipoReporte.label = "Demora promedio por conjunto de análisis"
        break;

      case 'Reporte de Muestras por Estado Mensual':
        await reportePorEstadoMensual(form).then((res) => {
          valores = res.data;
        });
        form.tipoReporte = "estado"
        tipoReporte.value = "estado"
        tipoReporte.label = "Cantidad de muestras por estado"
        break;

      case 'Reporte de Conteo de Muestras por Mes y Diagnóstico':
        await reporteConteoPorMesDiagnostico(form).then((res) => {
          valores = res.data;
        });
        form.tipoReporte = "diagnostico"
        tipoReporte.value = "diagnostico"
        tipoReporte.label = "Cantidad de muestras por estado de diagnóstico"
        break;

      case 'Reporte de Muestras por Causa de Falla':
        await reporteCausasFalla(form).then((res) => {
          valores = res.data;
        });
        form.tipoReporte = "fallas"
        tipoReporte.value = "fallas"
        tipoReporte.label = "Causas de fallas"
        break;

      case 'Reporte de Días de Demora en Órdenes de Trabajo':
        await reporteDiasDemoraOrdenesTrabajo(form).then((res) => {
          valores = res.data;
        });
        form.tipoReporte = "demoras"
        tipoReporte.value = "demoras"
        tipoReporte.label = "Días de demora por órden de trabajo"
        break;
      
      case 'Reporte de Puntos de Toma por Periodo':
        await puntoTomasPeriodo(form).then((res) => {
          valores = res.data;
        });
        form.tipoReporte = "cambios"
        tipoReporte.value = "cambios"
        tipoReporte.label = "Línea de cambios de estados"
        break;
    }
    
    let tipoRequest = "view";

    headerReporte.fechaInicioReporte = reporte.data.fecha_inicio_rep;
    headerReporte.fechaFinReporte = reporte.data.fecha_fin_rep;
    headerReporte.fechaCreacion = new Date(reporte.data.created_date).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' });
    headerReporte.autor = reporte.autor;

    setFormToView(form);
    setTipoReporteToView(tipoReporte);
    setValoresToView(valores);
    setTipoRequestToView(tipoRequest);
    setHeaderReporteToView(headerReporte);

    setIsLoading(false);
    setStatusModalView(true);
  }

  const handleClickButtonHeader = () => {
    setDatosModal("");
    setModalStatus(true);
  }

  const handleClickButonTable = (value, valueRow) => {
    if (value !== "Eliminar") {
      if (value === "Ver") {
        setIsLoading(true);
        handleVerReporte(valueRow)
      } else {
        setModalStatus(true);
        setDatosModal({ button: value.toLowerCase(), datos: valueRow });
      }
    } else {
      setReporteId(valueRow.data.id);
      setModalStatus2(true);
    }
  }

  const handleDeleteReporte = (id) => {
      setModalStatus2(false);
      deleteReporte(id).then(() => {
        setReporteId("");
        
        if(rows.length === 1) {
          setPaginaActual(paginaActual - 1)
        }

        fetchReportes();
      });
    };

  return (
    <Styled>
      <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
      <Modal
        datos={datosModal}
        isOpen={statusModal}
        type={"reportes"}
        onClose={({ msj, status }) => {
          setSuccess(false);
          setError(false);
          setKey(key + 1);

          if (status === "success") {
            setSuccess(true);
            setMsjToast(msj);
            fetchReportesBySuccess();
          }

          if (status === "error") {
            setError(true);
            setMsjToast(msj);
          }

          setModalStatus(false);
        }}
      />
      <HeaderTable
        styledHead="cfg"
        proveedores
        title="Reportes"
        labelButton={"Nuevo Reporte"}
        valueBuscador={(value) => {
          setSearchTerm(value)
          setPaginaActual(1)
        }}
        onClickButton={() => { handleClickButtonHeader() }}
        search
      />

      <Tables
        typeTable={"prop"}
        columns={columns}
        cRows={20}
        rows={rows}
        totalRows={itemsCount}
        pagination={true}
        onClickSwitch={(value) => { handleOrder(value) }}
        page={(e) => setPaginaActual(e + 1)}
        pages={pageCount}
        actualpage={paginaActual}
        //valueBuscador={valueBuscador}
        buttons={buttonsReportes}
        onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
      />

      <Modal2
        type="eliminar"
        isOpen={statusModal2}
        onClose={() => setModalStatus2(false)}
        onClickSuccess={() => {
          handleDeleteReporte(reporteId);
        }}
      ></Modal2>    

      <Modal
          datos={{ form: formToView, tipoReporte: tipoReporteToView, valores: valoresToView, tipoRequest: tipoRequestToView, headerReporte: headerReporteToView }}
          isOpen={statusModalView}
          type={"reportesGraph"}
          onClose={() => { 
            setStatusModalView(false);
            /*props.onClick({
              msj: "Reporte creado con exito.",
              status: "success",
            });*/
          }}
        />
    </Styled>
  )
}

export default Reportes

const Styled = styled.div`
  padding: 20px;
  margin: auto;
  .styledTables{
    //height: 100vh;
  }
`