import React, { useEffect, useState } from 'react'
import {
  DashBoardRight,
  BoxHeaderRight,
  TitleRight,
  DescriptionRight,
  BoxTable,
} from '../DashboardLaboratorioStyled';
import Tables from "../../../../../components/Tables/Tables";
import { getLatestOrdenesDeTrabajo } from "../../../../../api/Inicio";
import { deleteOrdenTrabajo } from "../../../../../api/Muestras";
import { buttonsDashboardLaboratorio } from '../../../../../components/ButtonsTable/ButtonsTable';
import { formatearFecha } from '../../../../../components/Utils/Utils.ts';
import Modal from '../../../../../components/Modal/Modal';
import Modal2 from '../../../../../components/Modal/Modal';
import Toast from "../../../../../components/Toast/Toast";
import { CircularProgress } from '@mui/material';
const columns = [
  { id: "cliente", label: "Cliente" },
  { id: "fechaProgr", label: "Fecha Progr." },
  { id: "codigo", label: "Código" },
  { id: "lote", label: "Lote" },
  { id: "planta", label: "Planta" },
  { id: "buttons" },
]

const RightDashboardLaboratorio = () => {
  const [rows, setRows] = useState("")
  const [typeModal, setTypeModal] = useState("ordenesTrabajo");
  const [statusModal, setModalStatus] = useState(false);
  const [datosModal, setDatosModal] = useState("");
  const [statusModal2, setModalStatus2] = useState(false);
  const [ordenId, setOrdenId] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [msjToast, setMsjToast] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleClickButonTable = (value, valueRow) => {
    const val = value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    if (val !== "eliminar" && val !== "diagnostico" && val !== "excel") {
      setModalStatus(true);
      setTypeModal("ordenesTrabajo");
      setDatosModal({ button: value.toLowerCase(), datos: valueRow });
    }
    if (val === "eliminar") {
      setOrdenId(valueRow.id);
      setModalStatus2(true);
    }
  };

  const handleDeleteOrden = (id) => {
    setModalStatus2(false);
    setIsLoading(true);
    deleteOrdenTrabajo(id).then(() => {
      setIsLoading(false);
      setOrdenId("");
    }).catch((err) => {
      setIsLoading(false);
      console.log(err);
      setMsjToast("Error al eliminar la orden de trabajo.");
      setError(true);
    });
  };
  useEffect(() => {
    const fetchLatestOrdenesDeTrabajo = async () => {
      try {
        const res = await getLatestOrdenesDeTrabajo();
        setRows(
          res.data.map((res) => {
            const fechaFormat = res.fecha_programada_otra;
            const fecha = formatearFecha(fechaFormat);
            return {
              fechaProgr: fecha,
              codigo: res.codigo_otra,
              lote: res.nro_lote_otra,
              denominacion: res.desc_otra,
              cliente: res.cliente.description,
              planta: res.planta.description,
              clienteId: res.cliente.id,
              plantaId: res.planta.id,
              estadoComp: res.estado ? res.estado : null,
              estado: res?.estado?.desc_ef_ot,
              id: res.id,
              fechaProgramada: res.fecha_programada_otra,
            };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchLatestOrdenesDeTrabajo();
  }, [ordenId, statusModal]);

  return (
    <>
      {isLoading &&
        <CircularProgress size={30}
          sx={{
            position: "fixed",
            bottom: "0",
            padding: "10px",
            zIndex: "9999"
          }}
        />
      }
      {success && (
        <Toast title={msjToast} close={() => setSuccess(false)} />
      )}
      {error && (
        <Toast
          type={"error"}
          title={msjToast}
          close={() => setError(false)}
        />
      )}

      <DashBoardRight>
        <BoxHeaderRight>
          <TitleRight>Órdenes de Trabajo Pendientes</TitleRight>
          <DescriptionRight>Aquí podrá consultar los últimos 10 Ordenes de Trabajo pendientes,
            para poder acceder a la consulta completa de informes, presione la opción “Informes de Muestras” que se encuentra en la cabecera
          </DescriptionRight>
        </BoxHeaderRight>
        <BoxTable>
          <Tables
            typeTable={"prop"}
            columns={columns}
            rows={rows}
            cRows={10}
            pagination={false}
            buttons={buttonsDashboardLaboratorio}
            onClickButtonTable={(value, valuesRow) => { handleClickButonTable(value, valuesRow) }}
            onClickSwitch={false}
          />
        </BoxTable>
        <Modal
          datos={datosModal}
          isOpen={statusModal}
          type={typeModal}
          onClose={({ msj, status }) => {
            setSuccess(false);
            setError(false);
            if (status === "success") {
              setSuccess(true);
              setMsjToast(msj);
            } else if (status === "error") {
              setError(true);
              setMsjToast(msj);
            } else if (status === "cancel") { }
            setModalStatus(false);
          }}
        />
        <Modal2
          type="eliminar"
          isOpen={statusModal2}
          onClose={() => setModalStatus2(false)}
          onClickSuccess={() => {
            handleDeleteOrden(ordenId);
          }}
        />

      </DashBoardRight>
    </>
  )
}

export default RightDashboardLaboratorio