import React, { useState, useReducer, useEffect } from 'react';
import { StyledModalcomponentReportesModalGraph } from '../../Styled';
import { TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/es";
import Input from '../../../Input/Input.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import Modal from '../../Modal.jsx';
import { demoraPromedioConjuntoAnalisis, getClienteOptions, getEquipoOptionsByPlanta, getPlantabyCliente2, getPlantaByClienteOptions, puntoTomasPeriodo, reporteCausasFalla, reporteConteoPorMesDiagnostico, reporteDiasDemoraOrdenesTrabajo, reportePorEstadoMensual, tiempoMedioEntreFallas } from '../../../../api/Clientes.js';
import { useAuth } from '../../../../context/AuthContext.jsx';
import Preloader from '../../../Preloader/Preloader.jsx';



const optionsSelect = [
  { value: 'analisis', label: 'Demora promedio por conjunto de análisis' },
  { value: 'estado', label: 'Cantidad de muestras por estado' },
  { value: 'diagnostico', label: 'Cantidad de muestras por estado de diagnóstico' },
  { value: 'fallas', label: 'Causas de fallas' },
  { value: 'demoras', label: 'Días de demora por órden de trabajo' },
  { value: 'tiempo', label: 'Tiempo medio entre fallas' },
  { value: 'cambios', label: 'Línea de cambios de estados' }
]

const ReportesModal = (props) => {
  const { token } = useAuth();
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [plantas, setPlantas] = useState([]);
  const [selectedPlanta, setSelectedPlanta] = useState(null);
  const [equipos, setEquipos] = useState([]);
  const [selectedEquipo, setSelectedEquipo] = useState(null);

  const [valoresReporte, setValoresReporte] = useState(null);
  const [tipoRequest, setTipoRequest] = useState(null);
  const [headerReporte, setHeaderReporte] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getClienteOptions().then((res) => {
      const unidades = res?.data?.map((item) => ({
        value: item.id,
        label: item.desc_cli,
      }));
      setClientes(unidades);

      if (token.clienteId !== "") {
        const matchOption = unidades.find(
          (option) => option.value === parseInt(token.clienteId, 10)
        );
        if (matchOption) {
          handleChange(matchOption, "clienteId");
          setSelectedCliente(matchOption);
        }
      }
    })
  }, [])

  useEffect(() => {
    if (selectedCliente !== null) {
      getPlantabyCliente2(selectedCliente.value).then((res) => {
        let aux = res.data.map((res) => {
          return { label: res.desc_pla, value: res.id };
        });

        // Si token.plantaId no está vacío, se aplica el filtro
        if (token.plantaId !== "") {
          const plantaIds = token.plantaId.split(',').map(id => id.trim());
          aux = aux.filter(option => plantaIds.includes(option.value.toString()));

          // Si es un único número, se filtra por ese número específico
          if (plantaIds.length === 1) {
            const matchOption = aux.find((option) => option.value === parseInt(plantaIds[0]));
            setSelectedPlanta(matchOption);
          } else {
            // Si hay varios números, buscamos el que coincida con el label en aux filtrado
            /*const matchOption = aux.find((option) => option.label === datos.planta);
            setSelectedPlanta(matchOption);*/
          }
        } else {
          /*const matchOption = aux.find((option) => option.label === datos.planta);
          setSelectedPlanta(matchOption);*/
        }

        // Establecer las plantas (filtradas si es necesario)
        setPlantas(aux);
      })
    }
  }, [selectedCliente])

  useEffect(() => {
    if (selectedPlanta !== null) {
      getEquipoOptionsByPlanta(selectedPlanta.value).then((res) => {
        const unidades = res?.data?.map((item) => ({
          value: item.id,
          label: item.desc_equi,
        }));
        setEquipos(unidades);
      })
    }
  }, [selectedPlanta])

  dayjs.locale("es");
  const propsReportes = (datosReportes) => {
    const ver = datosReportes?.button === "ver" ?? false;
    const editar = datosReportes?.button === "editar" ?? false;
    const datos = datosReportes?.datos ?? [];
    return { ver, editar, datos }
  }

  const { ver, editar, datos } = propsReportes(props.datos);
  const [formCompleto, setFormCompleto] = useState(false);
  const [statusModal, setModalStatus] = useState(false);
  const [selectedOptionTipoReporte, setSelectedOptionTipoReporte] = useState(null);


  const [form, setForm] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value };
      default:
        return state;
    }
  }, {
    clienteId: '',
    plantaId: '',
    equipoId: '',
    fechaInicio: props.datos.fechaInicio ? dayjs(props.datos.fechaInicio) : null,
    fechaFin: props.datos.fechaFin ? dayjs(props.datos.fechaFin) : null,
    tipoReporte: '',
  });

  useEffect(() => {
    const campoValido = campo => form[campo] !== '' && (campo !== 'habilitado' || form[campo]);
    const camposaValidar = ['tipoReporte'];
    const formularioCompleto = camposaValidar.every(campoValido);
    setFormCompleto(formularioCompleto && form.fechaInicio !== null && form.fechaFin !== null);
  }, [form]);

  const handleChange = (e, nameReactSelect) => {
    if (e.target) {
      const { name, value, checked, type } = e.target;
      const fieldValue = type === 'checkbox' ? checked : value;
      setForm({ type: 'SET_FIELD', field: name, value: fieldValue });
    } else {
      const value = e.value;
      const name = nameReactSelect;
      setForm({ type: "SET_FIELD", field: name, value: value });
    }
  };
  const handleChangeCalendar = (e, nameCalendar) => {
    const name = nameCalendar;
    setForm({ type: "SET_FIELD", field: name, value: e });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const titulo = () => {
    const titulo = "Generación de Reportes"
    return titulo
  }

  const renderButtons = () => {
    return (
      <div className="divButtons">
        <CustomButton
          onClick={props.onClick}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
        />

        <CustomButton
          label={"Confirmar"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={() => { handleConfirmar() /*setModalStatus(true)*/ }}
          disabled={!formCompleto}
        />

      </div>
    );
  };

  const handleConfirmar = () => {
    setIsLoading(true);
    const { tipoReporte, ...inputReporte } = form;
    inputReporte.fechaInicio = inputReporte.fechaInicio.toISOString().replace(".000", "");
    inputReporte.fechaFin = inputReporte.fechaFin.toISOString().replace(".000", "");

    setHeaderReporte({cliente: selectedCliente, planta: selectedPlanta, equipo: selectedEquipo, fechaInicioReporte: inputReporte.fechaInicio, fechaFinReporte: inputReporte.fechaFin})

    switch (tipoReporte) {
      case 'analisis':
        demoraPromedioConjuntoAnalisis(inputReporte).then((res) => {
          setValoresReporte(res.data);
          setTipoRequest("create");
          setModalStatus(true);
          setIsLoading(false);
        })
        break; 

      case 'estado':
        reportePorEstadoMensual(inputReporte).then((res) => {
          setValoresReporte(res.data);
          setTipoRequest("create");
          setModalStatus(true);
          setIsLoading(false);
        })
        break;

      case 'diagnostico':
        reporteConteoPorMesDiagnostico(inputReporte).then((res) => {
          setValoresReporte(res.data);
          setTipoRequest("create");
          setModalStatus(true);
          setIsLoading(false);
        })
        break; 
      
      case 'fallas':
        reporteCausasFalla(inputReporte).then((res) => {
          setValoresReporte(res.data);
          setTipoRequest("create");
          setModalStatus(true);
          setIsLoading(false);
        })
        break;

      case 'demoras':
        reporteDiasDemoraOrdenesTrabajo(inputReporte).then((res) => {
          setValoresReporte(res.data);
          setTipoRequest("create");
          setModalStatus(true);
          setIsLoading(false);
        })
        break;

      case 'tiempo':
        tiempoMedioEntreFallas(inputReporte).then((res) => {
          setValoresReporte(res.data);
          setTipoRequest("create");
          setModalStatus(true);
          setIsLoading(false);
        })
        break;

      case 'cambios':
        puntoTomasPeriodo(inputReporte).then((res) => {
          setValoresReporte(res.data);
          setTipoRequest("create");
          setModalStatus(true);
          setIsLoading(false);
        })
        break;

    }
  }

  return (
    <StyledModalcomponentReportesModalGraph>
      <Preloader title="Cargando..." subtitle={"Espere unos Segundos por favor..."} loading={isLoading} />
      <div className="reportes">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>
        <form className="formulario" onSubmit={handleSubmit}>
          <div className="col">
            <div className='aCol'>
              <div className="divLab">
                <p className="label">Fecha de Reporte: <span>{new Date().toLocaleDateString('es-ES')}</span></p>
              </div>
              <Input
                reactSelect
                options={clientes}
                value={selectedCliente}
                onChange={(e) => {
                  handleChange(e, "clienteId")
                  setSelectedCliente(e);
                }}
                isDisabled={token.clienteId !== "" || (ver && true)}
                name="cliente"
                placeholder={token.clienteId !== "" ? "Cliente" : ver ? "Cliente" : "Selecciona el cliente"}
              />
              <Input
                reactSelect
                options={equipos}
                value={selectedEquipo}
                onChange={(e) => {
                  handleChange(e, "equipoId")
                  setSelectedEquipo(e)
                }}
                isDisabled={equipos.length < 1 || (ver && true)}
                name="equipo"
                placeholder={ver ? "Equipo" : selectedPlanta === null ? "Equipo" : selectedEquipo === null ? "Selecciona el equipo" : "Equipo"}
              />
            </div>
            <div className='bCol'>
              <div className="divLab">
                <p className="label">Autor: <span>{token.firstName + " " + token.lastName}</span></p>
              </div>
              <Input
                reactSelect
                options={plantas}
                value={selectedPlanta}
                onChange={(e) => {
                  handleChange(e, "plantaId")
                  setSelectedPlanta(e)
                }}
                isDisabled={(token.plantaId && token.plantaId.split(',').length === 1) || plantas.length < 1 || (ver && true)}
                name="planta"
                placeholder={ver ? "Planta" : selectedCliente === null ? "Planta" : selectedPlanta === null ? "Selecciona la planta" : "Planta"}
              />

              <div className="divDatePicker">
                <LocalizationProvider dateAdapter={AdapterDayjs}>

                  <DatePicker
                    style={{ width: "50%" }}
                    label="Período inicio"
                    value={form.inicioPeriodo}
                    onChange={(newValue) => handleChangeCalendar(newValue, "fechaInicio")}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    style={{ width: "50%" }}
                    label="Período fin"
                    value={form.finPeriodo}
                    onChange={(newValue) => handleChangeCalendar(newValue, "fechaFin")}
                    renderInput={(params) => <TextField {...params} />}
                  />

                </LocalizationProvider>
              </div>

            </div>
          </div>
          <Input
            reactSelect
            options={optionsSelect}
            value={selectedOptionTipoReporte}
            onChange={(e) => {
              handleChange(e, "tipoReporte")
              setSelectedOptionTipoReporte(e);
            }}
            isDisabled={ver && true}
            name="tipoReporte"
            placeholder={ver ? "Tipo de Reporte"
              : selectedOptionTipoReporte === null ? "Selecciona el tipo de Reporte" : "Tipo de Reporte"}
          />
          {renderButtons()}
        </form>
        <Modal
          datos={{ form: form, tipoReporte: selectedOptionTipoReporte, valores: valoresReporte, tipoRequest: tipoRequest, headerReporte: headerReporte }}
          isOpen={statusModal}
          type={"reportesGraph"}
          onClose={() => { 
            setModalStatus(false);
            props.onClick({
              msj: "Reporte creado con exito.",
              status: "success",
            });
          }}
        />
      </div>

    </StyledModalcomponentReportesModalGraph>
  )
}

export default ReportesModal