import React, { useState } from 'react';
import { StyledModalcomponentReportesModalGraph } from '../../Styled.jsx';
import CustomButton from '../../../Button/CustomButton.tsx';
import Print from "../../../../assets/Icons/Buttons/Muestras/Print.svg";
import { RenderImgStyled } from '../../../Tables/TablesStyled.js';
import BarChartComponent from '../../../BarChart/BarChartComponent.jsx';
import { useMediaQuery, Divider } from "@mui/material";
import dayjs from 'dayjs';
import { useAuth } from '../../../../context/AuthContext.jsx';
import { getEstadosFinalesOptions } from '../../../../api/Configuracion.js';
import moment from 'moment';

const ReportesGraphModal = (props) => {
  const { token } = useAuth();

  // Meses en español
  const months = [
    "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", 
    "Septiembre", "Octubre", "Noviembre", "Diciembre"
  ];

  const generateFooterLabel = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    let labels = [];
    let current = new Date(start);
  
    while (current <= end) {
      const monthLabel = months[current.getMonth()] + " " + current.getFullYear().toString().slice(-2);
      labels.push(monthLabel);
  
      // Avanzamos al siguiente mes
      current.setMonth(current.getMonth() + 1);
    }
  
    return labels;
  };

  const titulo = () => {
    const titulo = "Generación de Reportes"
    return titulo
  }
  const renderButtons = () => {
    return (
      <div className="divButtons" style={{ marginLeft: "auto", padding: "20px 0px" }}>
        <CustomButton
          onClick={() => {

            if (props.onClick) {
              props.onClick();
            }
          }}
          label={"Cancelar"}
          variant="contained"
          className={`buttonColorDisabled`}
          size="small"
        />

        <CustomButton
          label={"Imprimir"}
          variant="contained"
          className={`buttonColorDefault btn`}
          type="submit"
          onClick={() => { alert() }}
          size="small"
          startIcon={
            <RenderImgStyled img={Print}
              style={{ padding: "5px" }} />
          }
        // disabled={!formCompleto}
        />

      </div>
    );
  };
  const isMobile = useMediaQuery("(max-width: 768px)");
  const renderBarChart = () => {
    // analisis = Demora promedio por conjunto de análisis
    // estado = Cantidad de muestras por estado
    // diagnostico = Cantidad de muestras por estado de diagnóstico
    // fallas = Causas de fallas
    // demoras = Días de demora por órden de trabajo
    // tiempo = Tiempo medio entre fallas
    // cambios = Línea de cambios de estados
    let defaultDataReportes;
    let footerLabel;
    let styled;
    let yDecimal;
    let type;
    let seriesTiempo;
    let columns;
    let rows;
    let captionTable;
    let captionLabel;
    switch (props.datos.form.tipoReporte) {
      case "estado":
        if(props.datos.valores !== null) {

          // Color fijo para todos
          //const fixedColor = "#82fc00";

          let dataAux = [];

          // Recorremos el array de datos para agregar los valores de cantidadMuestras agrupados por estadoFinalDesc
          props.datos.valores.forEach(item => {
            // Comprobamos si ya existe el label en el array final
            let existing = dataAux.find(report => report.label === item.estadoFinalDesc);
            
            if (existing) {
              // Si ya existe, agregamos la cantidadMuestras a los datos
              existing.data.push(item.cantidadMuestras);
            } else {
              // Si no existe, lo agregamos con el color fijo "#82fc00"
              dataAux.push({
                label: item.estadoFinalDesc,
                data: [item.cantidadMuestras],
                color: item.color_efm
              });
            }
          });
          
          defaultDataReportes = dataAux;
          footerLabel = generateFooterLabel(props.datos.valores[0].fecha_inicio_rep, props.datos.valores[0].fecha_fin_rep);
        } else {
          defaultDataReportes = [
            { label: "Normal", data: [10, 30], color: "#82fc00" },
            { label: "Precaucion", data: [40, 50], color: "#fceb00" },
            { label: "Peligro", data: [50, 70], color: "#fc0000" },
          ];
          footerLabel = [];
        }

        yDecimal = false;
        styled = "reportesEstado"
        break;

      case "analisis":
        if(props.datos.valores !== null) {
          let dataAux = [];
          let captionAux = [];
          let defaultDataAux = [];

          // Agrupar los datos por conjuntoAnalisisId
          const groupedByConjunto = props.datos.valores.reduce((acc, item) => {
            // Si no existe el grupo, lo inicializamos
            if (!acc[item.conjuntoAnalisisId]) {
              acc[item.conjuntoAnalisisId] = {
                conjuntoAnalisisNombre: item.conjuntoAnalisisNombre,
                clientes: []
              };
            }

            // Añadimos el cliente al grupo correspondiente
            acc[item.conjuntoAnalisisId].clientes.push(item);

            return acc;
          }, {});

          // Procesamos cada grupo para generar el formato requerido
          for (const conjuntoAnalisisId in groupedByConjunto) {
            const group = groupedByConjunto[conjuntoAnalisisId];

            // Para cada grupo de conjuntoAnalisisId, creamos el array con clientes
            const result = group.clientes.map(client => ({
              cliente: client.clienteNombre,
              totalMuestras: client.cantidadMuestras.toString(),
              promedioDias: client.demoraDiasPromedio.toString(),
            }));

            // Añadimos el resultado de este conjunto al array rows
            dataAux.push(result);

            // Añadimos la etiqueta para footerLabel con el conjuntoAnalisisId real
            captionAux.push(`${conjuntoAnalisisId < 10 ? `0${conjuntoAnalisisId}` : conjuntoAnalisisId}- ${group.conjuntoAnalisisNombre}`);
            defaultDataAux.push({
              label: `${conjuntoAnalisisId < 10 ? `0${conjuntoAnalisisId}` : conjuntoAnalisisId}- ${group.conjuntoAnalisisNombre}`,
              value: group.clientes[0].demoraDiasPromedioGlobal, // Asignamos el valor de demoraDiasPromedioGlobal
            });
          }

          rows = dataAux;
          captionLabel = captionAux;
          defaultDataReportes = defaultDataAux;
          footerLabel = captionAux;

          columns = [
            { id: "cliente", label: "Cliente" },
            { id: "totalMuestras", label: "Total de muestras" },
            { id: "promedioDias", label: "Promedio días" },
          ];

        } else {
          defaultDataReportes = [
            { label: "01- Conjunto basico", value: 12.33 },
            { label: "02- Conjunto completo", value: 15.55 },
            { label: "03- Conjunto completo2", value: 13.55 },
          ];
          footerLabel = ['01- Conjunto básico', '02- Conjunto completo', "03- Conjunto completo2"]
          columns = [
            { id: "cliente", label: "Cliente" },
            { id: "totalMuestras", label: "Total de muestras" },
            { id: "promedioDias", label: "Promedio días" },
          ];
          rows = [
            [
              { cliente: "Cliente 1", totalMuestras: "10", promedioDias: "360" },
              { cliente: "Cliente 2", totalMuestras: "12", promedioDias: "360" },
              { cliente: "Cliente 3", totalMuestras: "6", promedioDias: "250" },
            ],
            [
              { cliente: "Cliente 1", totalMuestras: "66", promedioDias: "360" },
              { cliente: "Cliente 2", totalMuestras: "05", promedioDias: "240" },
              { cliente: "Cliente 3", totalMuestras: "6", promedioDias: "250" },
            ],
            [
              { cliente: "Cliente 1", totalMuestras: "66", promedioDias: "360" },
              { cliente: "Cliente 2", totalMuestras: "05", promedioDias: "240" },
              { cliente: "Cliente 3", totalMuestras: "6", promedioDias: "250" },
            ],
          ];

          captionLabel = ["01- Conjunto básico", "02- Conjunto completo", "03- Conjunto completo2"]
        }
        
        captionTable = true
        styled = "reportesAnalisis"
        type = "analisis"
        break;

      case "diagnostico":

        if(props.datos.valores !== null) {
          let dataAux = [
            { label: "Pendiente", data: [], color: "#fc0000" },
            { label: "Publicada", data: [], color: "#2efc00" }
          ];

          props.datos.valores.forEach(item => {
            // Agregar contadorProcesada a "Pendiente"
            dataAux[0].data.push(item.contadorProcesada);
          
            // Agregar contadorPublicada a "Publicada"
            dataAux[1].data.push(item.contadorPublicada);
          });

          defaultDataReportes = dataAux;
          footerLabel = generateFooterLabel(props.datos.valores[0].fecha_inicio_rep, props.datos.valores[0].fecha_fin_rep);

        } else {

          defaultDataReportes = [
            { label: "Pendiente", data: [], color: "#fc0000" },
            { label: "Publicada", data: [], color: "#2efc00" },
          ];
          footerLabel = [];

        }
        
        styled = "reportesDiagnostico"
        yDecimal = false;
        break;

      case "fallas":
        type = "fallas";
        styled = "reportesCausasDeFalla";

        if(props.datos.valores !== null) {
          const totalContador = props.datos.valores.reduce((sum, item) => sum + item.contador, 0);

          // Generar el nuevo array con los datos en la estructura deseada
          const dataAux = [
            {
              data: props.datos.valores.map((item, index) => ({
                id: index, // El id es simplemente el índice de cada elemento en el array
                value: ((item.contador / totalContador) * 100).toFixed(2), // Calcula el porcentaje y lo redondea a 2 decimales
                label: item.desc_falla // Usamos el causa_falla_id para el label
              }))
            }
          ];

          defaultDataReportes = dataAux;

        } else {
          defaultDataReportes = [
            {
              data: [
                { id: 0, value: 0, label: "" },
              ],
            },
          ];
        }

        break;
      case "demoras":
        if(props.datos.valores !== null) {
          defaultDataReportes = props.datos.valores.map(item => ({
            label: item.codigo_otra,
            value: item.diasDemora
          }));
          footerLabel = props.datos.valores.map(item => item.codigo_otra);
        } else {
          defaultDataReportes = [
            { label: "Normal", value: 10 },
            { label: "Precaucion", value: 15 },
            { label: "Peligro", value: 20 },
            { label: "Normall", value: 10 },
            { label: "Pre", value: 15 },
            { label: "Pel", value: 20 },
          ];
          footerLabel = ['Normal', 'Precaucion', 'Peligro', "Normall", "Pre", "Pel"]
        }
        type = "demoras"
        styled = "reportesCausasDemoras"
        break;
      case "tiempo":
        if(props.datos.valores !== null) {
          const meses = {
            "01": "Enero",
            "02": "Febrero",
            "03": "Marzo",
            "04": "Abril",
            "05": "Mayo",
            "06": "Junio",
            "07": "Julio",
            "08": "Agosto",
            "09": "Septiembre",
            "10": "Octubre",
            "11": "Noviembre",
            "12": "Diciembre"
        };

          columns = [
            { id: "mes", label: "Mes" },
            { id: "cantidadMuestras", label: "Cantidad de muestras" },
            { id: "horasoperacion", label: "Horas operación" },
            { id: "tmbf", label: "TMBF" },
          ];

          defaultDataReportes = generateFooterLabel(props.datos.form.fechaInicio, props.datos.form.fechaFin);

          rows = props.datos.valores.map(item => {
            const mes = meses[item.yearMonth.split("-")[1]];  // Extraemos el mes
            const anio = item.yearMonth.split("-")[0].slice(-2);  // Extraemos los dos últimos dígitos del año
            const cantidadMuestras = item.cantidadMuestras;
            const horasoperacion = item.sumatoriaHorasUso;
            const tmbf = (horasoperacion / cantidadMuestras).toFixed(2); // Calculamos tmbf y lo redondeamos a 2 decimales

            return { 
                mes: `${mes} ${anio}`, 
                cantidadMuestras: cantidadMuestras.toString(), 
                horasoperacion: horasoperacion.toString(), 
                tmbf: isNaN(tmbf) ? 0 : tmbf
            };
          });

          seriesTiempo = [
            rows.map(item => parseFloat(item.tmbf))  // Extraemos tmbf y lo convertimos a número
          ];
        
        } else {
          defaultDataReportes = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
          ];
          seriesTiempo = [
            [36, 30, 42, 33,],
          ];
          columns = [
            { id: "mes", label: "Mes" },
            { id: "cantidadMuestras", label: "Cantidad de muestras" },
            { id: "horasoperacion", label: "Horas operación" },
            { id: "tmbf", label: "TMBF" },
          ];
          rows = [
            { mes: "Enero", cantidadMuestras: "10", horasoperacion: "360", tmbf: "36" },
            { mes: "Febrero", cantidadMuestras: "12", horasoperacion: "360", tmbf: "30" },
            { mes: "Marzo", cantidadMuestras: "6", horasoperacion: "250", tmbf: "42" },
            { mes: "Abril", cantidadMuestras: "15", horasoperacion: "500", tmbf: "33" },
          ];
        }
        type = "tiempo"
        styled = "reportesCausasTiempo"
        break;

      case "cambios":
        if(props.datos.valores !== null) {
          columns = [
            { id: "puntoDeToma", label: "Punto de Toma" },
          ];

          let dataAux = [];
          const pointMap = {};

          // Iteramos por cada fecha en el array original
          props.datos.valores.forEach(item => {
            // Iteramos por los puntos de cada fecha
            item.puntos.forEach(punto => {
                // Si el punto tiene una muestra (muestra !== null)
                if (punto.muestra) {
                    // Extraemos la información de la muestra
                    const { codigo_mue, color } = punto.muestra;
        
                    // Si el puntoDeToma ya existe en el pointMap, agregamos la fecha
                    if (pointMap[punto.descpunto]) {
                        pointMap[punto.descpunto][item.fecha] = (
                            <div style={{ background: color, padding: "5px" }}>
                                {codigo_mue}
                            </div>
                        );
                    } else {
                        // Si no existe, creamos un nuevo registro para ese punto
                        pointMap[punto.descpunto] = {
                            puntoDeToma: punto.descpunto,
                            [item.fecha]: (
                                <div style={{ background: color, padding: "5px" }}>
                                    {codigo_mue}
                                </div>
                            ),
                        };
                    }
                }
            });
        });

        Object.values(pointMap).forEach(row => {
          dataAux.push(row);
        });

          rows = dataAux;
        } else {
          columns = [
            { id: "puntoDeToma", label: "Punto de Toma" },
          ];
          rows = [
            { puntoDeToma: "puntoDeToma 1", "13/03/2024": <div style={{ background: "red", padding: "5px" }}>2580</div> },
            { puntoDeToma: "puntoDeToma 2", "10/01/2024": <div style={{ background: "green", padding: "5px" }}>3679</div> },
            { puntoDeToma: "puntoDeToma 3", "20/01/2024": <div style={{ background: "orange", padding: "5px" }}>3679</div> },
          ];
        }
          let fechaInicio = dayjs(props.datos.form.fechaInicio).format("MM/DD/YYYY");
          let fechaFin = dayjs(props.datos.form.fechaFin).format("MM/DD/YYYY");
          let fechaActual = dayjs(fechaInicio);
          const fechas = [];
  
          while (fechaActual <= dayjs(fechaFin)) {
            fechas.push(fechaActual.format('DD/MM/YYYY'));
            fechaActual = fechaActual.add(1, 'day');
          }
  
          fechas.forEach(fecha => {
            columns.push({
              id: fecha,
              label: fecha,
            });
          });
        type = "cambios";
        styled = "reportesCausasCambios";
        break;

      default:
        return null;
    }

    return (
      <BarChartComponent
        columns={columns}
        rows={rows}
        caption={captionTable}
        labelCaption={captionLabel}
        series={defaultDataReportes}
        seriesTiempo={seriesTiempo}
        footerLabel={footerLabel}
        width={!isMobile ? 1500 : 900}
        height={!isMobile ? 700 : 500}
        styled={styled}
        yDecimal={yDecimal}
        type={type}
      />
    );
  };
  return (
    <StyledModalcomponentReportesModalGraph sx={{ height: "90%", overflowY: "auto" }}>
      <div className="reportesGraphModal">
        <div className="divLabel">
          <p className="titulo">{titulo()}</p>
        </div>

        <div className="divHeader">
          <div className="aCol">
            <p className="label">Fecha de Reporte: <span className="span">{props.datos.tipoRequest !== null ? props.datos.tipoRequest === "create" ? new Date().toLocaleDateString('es-ES') : props.datos.headerReporte.fechaCreacion : ""}</span></p>
            <p className="label">Autor: <span className="span">{props.datos.tipoRequest !== null ? props.datos.tipoRequest === "create" ? token.firstName + " " + token.lastName : props.datos.headerReporte.autor : ""}</span></p>
          </div>
          <div className="bCol">
            <p className="label">Tipo de Reporte:</p>
            <div className="divTipo"><p className="label">{props.datos.tipoReporte?.label ?? ""}</p></div>
          </div>

        </div>
        <div className="divBody">
          <div className="divLabelBody">
            <div className="acolLabel">
              {props.datos.headerReporte.cliente && props.datos.headerReporte.cliente.label !== "" ? <p className="label">Cliente: <span className="span">{props.datos.headerReporte.cliente.label}</span></p> : <></>}
              {props.datos.headerReporte.planta && props.datos.headerReporte.planta.label !== "" ? <p className="label">Planta: <span className="span">{props.datos.headerReporte.planta.label}</span></p> : <></>}
            </div>
            <div className="bcolLabel">
              {props.datos.headerReporte.equipo && props.datos.headerReporte.equipo.label !== "" ? <p className="label">Equipo: <span className="span">{props.datos.headerReporte.equipo.label}</span></p> : <></>}
              <p className="label">Período: <span className="span">{props.datos.headerReporte.fechaInicioReporte !== null && props.datos.headerReporte.fechaFinReporte !== null ? new Date(props.datos.headerReporte.fechaInicioReporte).toLocaleDateString('es-ES') + ' - ' + new Date(props.datos.headerReporte.fechaFinReporte).toLocaleDateString('es-ES') : ""}</span></p>
            </div>
          </div>

          <div className="ccolButtons">
            {props.datos.form.tipoReporte === "analisis" &&
              <p className="label">
                NOTA: se toma como inicio la Fecha de notificación de la muestra y como fin la Fecha de Publicación
              </p>
            }
            {props.datos.form.tipoReporte === "demoras" &&
              <p className="label">
                NOTA: El cálculo se realiza desde la fecha de notificación al Cliente hasta la fecha de finalización.
              </p>
            }
            {props.datos.form.tipoReporte === "tiempo" &&
              <p className="label">
                NOTA: Se toman para el cálculo.....
              </p>
            }
            {props.datos.form.tipoReporte === "cambios" &&
              <p className="label">
                NOTA: Cada cuadro indica en su interior, el número de muestra correspondiente y los colores representan el estado de la muestra..
              </p>
            }
            {renderButtons()}
          </div>
        </div>
        <Divider />
        <div className="divBarChart">

          {props.datos.form.tipoReporte === "fallas" &&
            <p className="titulo" style={{ textAlign: "center", width: "95%" }}>
              Cantidad de fallas</p>
          }
          {props.datos.form.tipoReporte === "demoras" &&
            <p className="titulo" style={{ textAlign: "center", width: "95%" }}>
              Días de demora por OT</p>
          }

          {renderBarChart()}
        </div>
        <Divider />

      </div>

    </StyledModalcomponentReportesModalGraph >
  )
}

export default ReportesGraphModal;