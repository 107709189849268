import React from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import { colors, fonts } from '../../assets/styles/theme.ts';
import { DeviceThermostat } from '@mui/icons-material';
import { colorClear } from '../ClearColor/ColorClear.js';

interface Props {
  label?: string;
  sub?: string;
  error?: boolean;
  active?: boolean;
  onClick?: () => void;
  backgroundColor?: string;
  icon?: boolean;
}

const BoxResult = ({ label, sub, error, active, onClick, backgroundColor, icon }: Props) => {

  const renderBody = () => {
    const labelColor = (
      <Typography sx={{
        color: colorClear(backgroundColor ?? "#FFFFFF") ? "white" : "black",
        fontSize: "20px",
      }}>
        {label}
      </Typography>
    );

    const subColor = (
      <Typography sx={{
        color: colorClear(backgroundColor ?? "#FFFFFF") ? "white" : "black",
        fontSize: "15px",
      }}>
        {sub}
      </Typography>
    );

    if (!icon) {
      return (
        <>
          {labelColor}
          {subColor}
        </>
      );
    } else {
      return (
        <BoxBodyIcon>
          <Box sx={{ display: "flex", gap: "5px" }}>
            <DeviceThermostat sx={{
              color: colorClear(backgroundColor ?? "#FFFFFF") ? "white" : "black",
            }} />
            {labelColor}
          </Box>
          <Box sx={{ textAlign: "start" }}>
            {subColor}
          </Box>
        </BoxBodyIcon>
      );
    }
  }
  return (
    <Styled error={error} active={active} onClick={onClick} backgroundColor={backgroundColor}>
      {renderBody()}
    </Styled>
  )
}

export default BoxResult

interface StyledProps {
  error?: boolean;
  active?: boolean;
  backgroundColor?: string;
  onClick?: () => void;
}

const Styled = styled(Box) <StyledProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding:10px 0px;
  gap: 5px;
  border-radius: 10px;
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};
  border: ${({ active, error }) => {
    if (active && !error) return `5px solid ${colors.turquoise}`;
    if (active && error) return `5px solid ${colors.turquoise}`;
    return 'none';
  }};
  width: 150px;
  height: 50px;
  background: ${({ backgroundColor, error }) =>
    backgroundColor ? backgroundColor : (error ? colors.error : colors.success)};
  & .MuiTypography-root {
    font-weight: 700;
    font-size: '20px';
    font-family: ${fonts.primary};
  }
`;

const BoxBodyIcon = styled(Box)`
  display: flex;
  flex-direction: column;
  margin:auto;
`